import createAxiosInstance from "./axios";

let API_SERVER_PREFIX =
  process.env.REACT_APP_SERVER_DOMAIN +
  process.env.REACT_APP_SERVER_ENVIRONMENT;

const { getRequest, postRequest, putRequest, getFileRequest } =
  createAxiosInstance(API_SERVER_PREFIX);

export const getProperties = () => getRequest(`/api/v1/properties`);

export const getPromotionsSummary = ({ startDate, endDate, propertyId }) =>
  getRequest(
    `/api/v1/promo-codes/summary?startDate=${startDate}&endDate=${endDate}&propertyId=${propertyId}`
  );

export const getPromotions = (pathParams) => {
  return getRequest(`/api/v1/promo-codes${pathParams}`);
};

export const searchPromotions = (pathParams) => {
  return getRequest(`/api/v1/promo-codes/search${pathParams}`);
};

export const getRatePlansByProperty = (propertyId) => {
  return getRequest(`/api/v1/rate-plans/${propertyId}`);
};

export const getRoomsByProperty = (propertyId) => {
  return getRequest(`/api/v1/room-types/${propertyId}`);
};

export const getPromo = (promoCodeId, propertyId) => {
  return getRequest(
    `/api/v1/promo-codes/${promoCodeId}/property/${propertyId}`
  );
};

export const savePromo = (promo, isDraft, calculationType) => {
  console.log(
    `/api/v1/promo-codes?discountOption=${calculationType}${isDraft ? "&draft=true" : ""}`
  );
  return postRequest(
    `/api/v1/promo-codes?calculationType=${calculationType}${isDraft ? "&draft=true" : ""}`,
    promo
  );
};

export const disablePromo = (promoId, propertyId) => {
  return putRequest(
    `/api/v1/promo-codes/disable?promoCodeId=${promoId}&propertyId=${propertyId}`,
    {}
  );
};

export const activatePromo = (promoId, propertyId) => {
  return getRequest(
    `/api/v1/promo-codes/${promoId}/property/${propertyId}/active`
  );
};

export const getPowerBiEmbedConfig = async () => {
  return await getRequest(`/api/v1/power-bi/embed-config`);
};

export const downloadPromotionsInXlsx = (pathParams) =>
  getFileRequest(`api/v1/promo-codes/export${pathParams}`);
