import React, { useState } from "react";
import { toast } from "react-toastify";
import docDownload from "../../assets/img/document-download.png";
import { Button, Spinner } from "reactstrap";

export default function ExportToExcel({
  handleExport,
  buttonLabel = "Export XSLX",
  apiCall,
  requestBody,
}) {
  const [isLoading, setIsLoading] = useState(false);

  function defaultHandleExport() {
    setIsLoading(true);

    apiCall(requestBody)
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        let filename = contentDisposition
          ? contentDisposition.includes("filename=")
            ? contentDisposition.split("filename=")[1]
            : contentDisposition
          : null;
        if (!filename) {
          toast.success("Exported file will be mailed!");
          setIsLoading(false);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.body]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  return (
    <Button
      outline
      disabled={isLoading}
      onClick={() =>
        !isLoading && handleExport ? handleExport() : defaultHandleExport()
      }
    >
      {isLoading && <Spinner size={"sm"} />}
      <img src={docDownload} alt="" /> &nbsp; {buttonLabel}
    </Button>
  );
}
