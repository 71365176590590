import React, { useEffect, useMemo } from "react";
import { InputNumber, InputPicker } from "rsuite";
import { FormGroup, Label } from "reactstrap";
const offerTypes = [
  { label: "Percentage discount on overall price", value: 2 },
  { label: "Flat discount on overall price", value: 1 },
];
const calculationTypes = [
  { label: "Apply discount to total", value: 1 },
  { label: "Apply discount to adults only", value: 2 },
  { label: "Apply discount to 1st adult", value: 0 },
];
function OfferType({
  onFieldChange,
  promo,
  setIsNextBtnDisabled,
  calculationType,
  setCalculationType,
}) {
  const handleChange = (newValue) => {
    onFieldChange("rateRule.ruleVO.discountVO.mCompDiscountType", newValue);
    onFieldChange("rateRule.ruleVO.discountVO.fixedDiscountAmount", 0);
    onFieldChange("rateRule.ruleVO.discountVO.percentDiscountAmount", 0);
  };

  const handleCalculationTypeChange = (value) => {
    setCalculationType(value);
  };

  const handleDiscountChange = (value) => {
    const newValue = +value;
    if (
      promo.rateRule.ruleVO.discountVO.mCompDiscountType === offerTypes[0].value
    ) {
      onFieldChange(
        "rateRule.ruleVO.discountVO.percentDiscountAmount",
        newValue
      );
    } else {
      onFieldChange("rateRule.ruleVO.discountVO.fixedDiscountAmount", newValue);
    }
  };

  const getDiscountValue = useMemo(() => {
    if (
      promo.rateRule.ruleVO.discountVO.mCompDiscountType === offerTypes[0].value
    ) {
      return promo.rateRule.ruleVO.discountVO.percentDiscountAmount ?? 0;
    } else {
      return promo.rateRule.ruleVO.discountVO.fixedDiscountAmount ?? 0;
    }
  }, [promo]);

  useEffect(() => {
    if (!getDiscountValue || getDiscountValue < 0) {
      setIsNextBtnDisabled(true);
    } else {
      setIsNextBtnDisabled(false);
    }
  }, [getDiscountValue, setIsNextBtnDisabled]);

  return (
    <div className="offer-type">
      <div className={"mt-4"}>
        <Label for="offerType">Type</Label>
        <InputPicker
          id="offerType"
          value={promo.rateRule.ruleVO.discountVO.mCompDiscountType}
          onChange={(value) => handleChange(value)}
          placeholder="Choose an option"
          data={offerTypes}
          block
        />
      </div>
      <div className={"mt-4"}>
        <Label for="discount">
          {promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
          offerTypes[0].value
            ? "Discount applied in %"
            : "Flat Discount"}
        </Label>
        <FormGroup>
          <InputNumber
            id="discount"
            name="discount"
            value={getDiscountValue}
            min={0}
            max={999}
            onChange={(value) => handleDiscountChange(value)}
            placeholder={
              promo.rateRule.ruleVO.discountVO.mCompDiscountType ===
              offerTypes[0].value
                ? "Discount applied in %"
                : "Flat Discount"
            }
          />
        </FormGroup>
      </div>
      <div className={"mt-4"}>
        <Label for="calculationMethod">Calculation method</Label>
        <InputPicker
          id="calculationMethod"
          value={calculationType}
          onChange={handleCalculationTypeChange}
          placeholder="Choose an option"
          data={calculationTypes}
          cleanable={false}
          block
        />
      </div>
    </div>
  );
}

export default OfferType;
