import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import {
  downloadPromotionsInXlsx,
  getPromotions,
  searchPromotions,
} from "../../api";
import options from "../../assets/img/options.png";
import { calculateDates } from "../../constants/utils";
import ExportToExcel from "../../components/export/ExportToExcel";

function PromotionsTable({
  selectedProperty,
  onEdit,
  onDisable,
  onActivate,
  isGetPromosList,
}) {
  const [promotions, setPromotions] = useState([]);
  const [promoTitle, setPromoTitle] = useState("All Promos");
  const [pagable, setPagable] = useState(null);
  const [range, setRange] = useState("all");
  //const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [confirmDisableModal, setConfirmDisableModal] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState(null);

  useEffect(() => {
    if (selectedProperty?.propertyId && isGetPromosList) {
      getPromos({
        startDate: null,
        endDate: null,
        propertyId: selectedProperty.propertyId,
      });
    }
  }, [selectedProperty, isGetPromosList]);

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    switch (currentPage) {
      case "first":
        getPromos({ propertyId: selectedProperty.propertyId, page: 0 });
        break;
      case "left":
        if (pagable.pageNumber > 0)
          getPromos({
            propertyId: selectedProperty.propertyId,
            page: pagable.pageNumber - 1,
          });
        break;
      case "center":
        if (pagable.pageNumber === 0)
          getPromos({ propertyId: selectedProperty.propertyId, page: 1 });
        break;
      case "right":
        if (pagable.pageNumber === 0) {
          getPromos({ propertyId: selectedProperty.propertyId, page: 2 });
        } else if (pagable.pageNumber < pagable.totalPages - 1)
          getPromos({
            propertyId: selectedProperty.propertyId,
            page: pagable.pageNumber + 1,
          });
        break;
      case "last":
        getPromos({
          propertyId: selectedProperty.propertyId,
          page: pagable.totalPages - 1,
        });
        break;
      default:
        getPromos({ propertyId: selectedProperty.propertyId, page: 0 });
    }
  };

  const getPromos = (req) => {
    const pathParams = `?propertyId=${req.propertyId}`;
    const startDateParam = req.startDate ? `&startDate=${req.startDate}` : "";
    const endDateParam = req.endDate ? `&endDate=${req.endDate}` : "";
    const pageParam =
      req.page || req.page === 0 ? `&page=${req.page}` : "&page=0";
    getPromotions(
      `${pathParams}${startDateParam}${endDateParam}${pageParam}&size=10`
    ).then(
      (res) => {
        setPromotions(res.data.data.content);
        setPagable({
          ...res.data.data.pageable,
          totalElements: res.data.data.totalElements,
          totalPages: res.data.data.totalPages,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleRangeChange = (e) => {
    setRange(e.target.value);
    switch (e.target.value) {
      case "all":
        getPromos({
          startDate: null,
          endDate: null,
          propertyId: selectedProperty.propertyId,
        });
        setPromoTitle("All Promos");
        break;
      case "week":
        getPromos({
          startDate: calculateDates("LAST_7_DAYS").startDate,
          endDate: calculateDates("LAST_7_DAYS").endDate,
          propertyId: selectedProperty.propertyId,
        });
        setPromoTitle("Last 7 days");
        break;
      case "month":
        getPromos({
          startDate: calculateDates("LAST_30_DAYS").startDate,
          endDate: calculateDates("LAST_30_DAYS").endDate,
          propertyId: selectedProperty.propertyId,
        });
        setPromoTitle("Last 30 days");
        break;
      case "year":
        getPromos({
          startDate: calculateDates("LAST_365_DAYS").startDate,
          endDate: calculateDates("LAST_365_DAYS").endDate,
          propertyId: selectedProperty.propertyId,
        });
        setPromoTitle("Last 1 year");
        break;
      default:
        getPromos({
          startDate: null,
          endDate: null,
          propertyId: selectedProperty.propertyId,
        });
        setPromoTitle("All Promos");
    }
  };

  const searchPromo = (e) => {
    const search = e.target.value;
    //setSearch(search);
    if (search.length > 2) {
      searchPromotions(
        `?propertyId=${selectedProperty.propertyId}&value=${search}&size=10`
      ).then(
        (res) => {
          setPromotions(res.data.data.content);
          setPagable({
            ...res.data.data.pageable,
            totalElements: res.data.data.totalElements,
            totalPages: res.data.data.totalPages,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else if (search.length === 0) {
      getPromos({
        startDate: null,
        endDate: null,
        propertyId: selectedProperty.propertyId,
      });
    }
  };

  /*const getRequestBody = () => {
    const pathParams = `?propertyId=${selectedProperty.propertyId}`;
    const startDateParam =
      range !== "all" ? `&startDate=${calculateDates(range).startDate}` : "";
    const endDateParam =
      range !== "all" ? `&endDate=${calculateDates(range).endDate}` : "";
    const searchParam = search ? `&search=${search}` : "";
    return `${pathParams}${startDateParam}${endDateParam}${searchParam}`;
  };*/

  const getRequestBodyForExcel = () => {
    return `?propertyId=${selectedProperty.propertyId}`;
  };

  const openDD = (index) => {
    setIsOpen(!isOpen);
    setOpenIndex(index);
  };

  const confirmDisablePromo = () => {
    setConfirmDisableModal(false);
    onDisable(selectedPromo);
    setSelectedPromo(null);
  };

  const getDateRangeAsString = (st, ed) => {
    if (!st && !ed) return "N/A";
    if (!st && ed) return "Till " + ed;
    if (st && !ed) return "From " + st;
    return st + " to " + ed;
  };

  return (
    <div className="promo-table-container">
      <div className="table-details">
        <h6 className="promo-table-header">{promoTitle}</h6>
        <div>
          <InputGroup>
            <Input onChange={searchPromo} placeholder="Search" />
            <InputGroupText>
              <span className="mglass">&#9906;</span>
            </InputGroupText>
          </InputGroup>
        </div>
        <div>&nbsp;&nbsp;</div>
        <ExportToExcel
          apiCall={downloadPromotionsInXlsx}
          requestBody={getRequestBodyForExcel()}
        />
        <div>
          <FormGroup className="promotions-filter-form mt-3">
            <Input
              type="select"
              name="select"
              id="promotions-filter-select"
              style={{ border: "none" }}
              value={range}
              onChange={handleRangeChange}
            >
              <option value={"all"}>All Promos</option>
              <option value={"week"}>Last 7 days</option>
              <option value={"month"}>last 30 days</option>
              <option value={"year"}>last 1 year</option>
            </Input>
          </FormGroup>
        </div>
      </div>
      <Table hover>
        <thead>
          <tr>
            <th>Promo Name</th>
            <th>Discount</th>
            <th>Unit</th>
            <th>Stay Dates</th>
            <th>Booking Window</th>
            <th>Revenue</th>
            <th>Created</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {promotions &&
            promotions.length > 0 &&
            promotions.map((promo, index) => (
              <tr key={index}>
                <td>{promo.name}</td>
                <td>{promo.discountValue}</td>
                <td>
                  {promo.discountType && promo.discountType === "1"
                    ? selectedProperty.acceptedCurrencies
                      ? selectedProperty.acceptedCurrencies[0]
                      : "USD"
                    : "%"}
                </td>
                <td>
                  {getDateRangeAsString(
                    promo.stayDates.start,
                    promo.stayDates.end
                  )}
                </td>
                <td>
                  {getDateRangeAsString(
                    promo.bookingWindow.start,
                    promo.bookingWindow.end
                  )}
                </td>
                <td>{promo.revenue}</td>
                <td>{promo.created.split("T")[0]}</td>
                <td>
                  <div className={"promo-status " + promo.status}>
                    {promo.status}
                  </div>
                </td>
                <td>
                  <Dropdown
                    isOpen={isOpen && openIndex === index}
                    toggle={() => openDD(index)}
                  >
                    <DropdownToggle data-toggle="dropdown" tag="span">
                      <img src={options} alt="" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {promo.status !== "Inactive" && (
                        <div
                          className="dd-option"
                          onClick={() => {
                            setConfirmDisableModal(true);
                            setSelectedPromo(promo);
                          }}
                        >
                          Disable
                        </div>
                      )}

                      {promo.status === "Draft" && (
                        <div
                          className="dd-option"
                          onClick={() => {
                            onActivate(promo);
                          }}
                        >
                          Activate
                        </div>
                      )}
                      <div className="dd-option" onClick={() => onEdit(promo)}>
                        Edit
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {pagable && pagable.totalPages > 1 && (
        <Pagination aria-label="Page navigation example" size={"md"}>
          <PaginationItem disabled={pagable?.pageNumber <= 0}>
            <PaginationLink
              first
              href="#"
              onClick={(e) => handlePagination(e, "first")}
            />
          </PaginationItem>
          <PaginationItem active={pagable?.pageNumber === 0}>
            <PaginationLink
              href="#"
              onClick={(e) => handlePagination(e, "left")}
            >
              {pagable?.pageNumber === 0 ? 1 : pagable?.pageNumber}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem active={pagable?.pageNumber !== 0}>
            <PaginationLink
              href="#"
              onClick={(e) => handlePagination(e, "center")}
            >
              {pagable?.pageNumber === 0 ? 2 : pagable?.pageNumber + 1}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem
            disabled={pagable?.pageNumber >= pagable?.totalPages - 1}
          >
            <PaginationLink
              href="#"
              onClick={(e) => handlePagination(e, "right")}
            >
              {pagable?.pageNumber === 0 ? 3 : pagable?.pageNumber + 2}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem
            disabled={pagable?.pageNumber >= pagable?.totalPages - 1}
          >
            <PaginationLink
              href="#"
              last
              onClick={(e) => handlePagination(e, "last")}
            />
          </PaginationItem>
        </Pagination>
      )}
      {(!promotions || !promotions.length) && <div>No Data</div>}
      {confirmDisableModal && (
        <Modal
          isOpen={confirmDisableModal}
          toggle={() => {
            setConfirmDisableModal((prevState) => !prevState);
            setSelectedPromo(null);
          }}
          size="md"
          returnFocusAfterClose={false}
          className="confirm-disable-modal"
        >
          <ModalHeader
            toggle={() => {
              setConfirmDisableModal((prevState) => !prevState);
              setSelectedPromo(null);
            }}
          >
            Confirm Disable Promo
          </ModalHeader>
          <ModalBody>
            Are to sure to disable promo: {selectedPromo?.name}?
          </ModalBody>
          <ModalFooter>
            <Button
              outline
              onClick={() => {
                setConfirmDisableModal(false);
                setSelectedPromo(null);
              }}
            >
              Cancel
            </Button>{" "}
            <Button className="disable-btn" onClick={confirmDisablePromo}>
              Disable
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

export default PromotionsTable;
